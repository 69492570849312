import React, {useEffect, useState} from 'react'
import {ElementOrLoader} from "../components/util"
import { useNavigate } from "@reach/router"

const ClearCache = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    navigate('/login');
  })
  return <ElementOrLoader loading/>
}

export default ClearCache;
